import { Component, OnChanges, OnInit } from '@angular/core';
import { AuthenticationService, BaseService, SharedService } from '../../_services/_index';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';

@Component({
  selector: 'app-toolbar-component',
  templateUrl: './toolbar-component.component.html',
  styleUrl: './toolbar-component.component.scss'
})
export class ToolbarComponent implements OnInit, OnChanges {

  hasToken: boolean;

  isLogged$: Observable<boolean>;
  userEmail: string = '';
  initials: string = '';

  private isAuthenticatedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isAuthenticated$: Observable<boolean> = this.isAuthenticatedSubject.asObservable();

  constructor(private authService: AuthenticationService,
    private baseService: BaseService,
    private sharedService: SharedService,
    private cookieService: CookieService,
    private router: Router) {
  }

  ngOnChanges() {
    this.isLogged$ = this.sharedService.currentIsloggedIn;
    this.hasToken = this.cookieService.get('currentUser') ? true : false;
    this.isAuthenticatedSubject.next(this.checkAuth());
  }

  logout() {
    this.authService.logout();
    this.hasToken = false;
    this.isAuthenticatedSubject.next(false);
    this.sharedService.changeLogginInStatus(false);
    this.router.navigate(['/main/login']);
  }



  ngOnInit(): void {
    this.isAuthenticatedSubject.next(this.checkAuth());
    this.hasToken = this.cookieService.get('currentUser') ? true : false;
    if (this.hasToken) {
      try {
        const token = this.baseService.getUserToken();

        this.userEmail = token.sub;
        this.initials = this.userEmail[0].toUpperCase();
      } catch (e) {
        console.error('Error getting user info:', e);
      }
    }
    else {
      this.userEmail = '';
      this.initials = '';
      this.isLogged$ = of(false);
    }
  }

  addNewWebsite(): void {
    this.router.navigate(['/business/dashboard/domain']);
  }

  checkAuth(): boolean {
    const token = this.cookieService.get('currentUser');
    if (token) {
      // return this.baseService.helper.isTokenExpired(token);
      return true;
    }

    return false;
  }
}
