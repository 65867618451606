<!-- src/app/landing-page/landing-page-container/landing-page-container.component.html -->
<div class="landing-wrapper">
    <!-- Hero Section -->
    <section class="hero-section">
      <div class="hero-content">
        <h1>WordPress Hosting Made Simple</h1>
        <p>Just $1/month forever. No hidden fees. No technical knowledge needed.</p>
        <div class="hero-actions">
          <button mat-raised-button color="accent" (click)="navigateToSignup()" class="get-started-btn">
            Get Started for $1
          </button>
          <button mat-stroked-button class="learn-more-btn">Learn More</button>
        </div>
      </div>
    </section>
  
    <!-- Features Section -->
    <section class="features-section">
      <div class="container">
        <h2>Everything You Need to Succeed Online</h2>
        <div class="features-grid">
          <mat-card class="feature-card">
            <div class="icon-wrapper">
              <mat-icon>flash_on</mat-icon>
            </div>
            <h3>Lightning Fast</h3>
            <p>Optimized servers ensure your website loads quickly for every visitor.</p>
          </mat-card>
  
          <mat-card class="feature-card">
            <div class="icon-wrapper">
              <mat-icon>security</mat-icon>
            </div>
            <h3>Always Secure</h3>
            <p>Automatic updates and security features keep your site protected.</p>
          </mat-card>
  
          <mat-card class="feature-card">
            <div class="icon-wrapper">
              <mat-icon>dashboard</mat-icon>
            </div>
            <h3>Simple Dashboard</h3>
            <p>Easy-to-use interface for managing your website with confidence.</p>
          </mat-card>
  
          <mat-card class="feature-card">
            <div class="icon-wrapper">
              <mat-icon>backup</mat-icon>
            </div>
            <h3>Auto Backups</h3>
            <p>Daily automatic backups keep your content safe and secure.</p>
          </mat-card>
        </div>
      </div>
    </section>
  
    <!-- Target Users Section -->
    <section class="target-section">
      <div class="container">
        <h2>Perfect For</h2>
        <div class="target-grid">
          <mat-card class="target-card" *ngFor="let user of targetUsers">
            <div class="icon-wrapper">
              <mat-icon>{{user.icon}}</mat-icon>
            </div>
            <h3>{{user.title}}</h3>
            <p>{{user.description}}</p>
          </mat-card>
        </div>
      </div>
    </section>
  
    <!-- Benefits Section -->
    <section class="benefits-section">
      <div class="container">
        <h2>Why Choose Us?</h2>
        <div class="benefits-grid">
          <div class="benefit-card">
            <div class="icon-wrapper">
              <mat-icon>attach_money</mat-icon>
            </div>
            <h3>Simple Pricing</h3>
            <p>Just $1/month, forever. No hidden fees.</p>
          </div>
  
          <div class="benefit-card">
            <div class="icon-wrapper">
              <mat-icon>rocket_launch</mat-icon>
            </div>
            <h3>One-Click Install</h3>
            <p>Get WordPress running in seconds.</p>
          </div>
  
          <div class="benefit-card">
            <div class="icon-wrapper">
              <mat-icon>speed</mat-icon>
            </div>
            <h3>Optimized Speed</h3>
            <p>Built-in caching and optimization.</p>
          </div>
        </div>
      </div>
    </section>
  
    <!-- Trust Indicators Section -->
    <section class="trust-section">
      <div class="container">
        <div class="trust-grid">
          <div class="trust-item">
            <div class="number">$1</div>
            <p>Fixed Monthly Price</p>
          </div>
          <div class="trust-item">
            <div class="number">100%</div>
            <p>Transparent Pricing</p>
          </div>
          <div class="trust-item">
            <div class="number">1-Click</div>
            <p>WordPress Install</p>
          </div>
        </div>
      </div>
    </section>
  
    <!-- FAQ Section -->
    <section class="faq-section">
      <div class="container">
        <h2>Frequently Asked Questions</h2>
        <mat-accordion>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                How does $1/month hosting work?
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p>We keep our costs low by optimizing our infrastructure and automating our processes. This allows us to pass the savings on to you while maintaining high-quality service.</p>
          </mat-expansion-panel>
  
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                What's included in the hosting?
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p>You get WordPress pre-installed, automatic backups, security updates, SSL certificate, and our easy-to-use control panel. Everything you need to run a successful website.</p>
          </mat-expansion-panel>
  
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Do I need technical knowledge?
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p>Not at all! Our platform is designed for beginners. We handle all the technical details so you can focus on your content and business.</p>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </section>
  
    <!-- CTA Section -->
    <section class="cta-section">
      <div class="container">
        <h2>Start Your Website Today</h2>
        <p>Join thousands of satisfied customers who trust us with their online presence.</p>
        <button mat-raised-button color="accent" (click)="navigateToSignup()" class="cta-button">
          Get Started Now
        </button>
      </div>
    </section>
  </div>