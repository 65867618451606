
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoadingService } from '../_services/loading.service';

@Injectable()
export class AppLoadingInterceptor implements HttpInterceptor {

    constructor(private loadingService: LoadingService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        console.log('AppLoadingInterceptor');
        this.loadingService.show();
        return next.handle(request).pipe(
            finalize(() => this.loadingService.hide())
        );
    }
}