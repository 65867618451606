import { Component } from '@angular/core';
import { LoadingService } from './_services/loading.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  loading$: Observable<boolean>;

  constructor(
    private loadingService: LoadingService,
    private router: Router
  ) {
    this.loading$ = this.loadingService.loading$;
  }

  isLoginPage(): boolean {
    return this.router.url.includes('/main/login');
  }

  title = 'control-panel';
}