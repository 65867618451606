import { AppConfig } from '../_configs/config';
import { IAppConfig } from '../_models/_index';
import { environment as defaultEnvironment } from './environment';

export const EnvironmentLoader = new Promise<IAppConfig>((resolve, reject) => {
    var settings: IAppConfig;

    var xmlhttp = new XMLHttpRequest(),
        method = 'GET',
        url = './assets/_environments/environment.json';
    xmlhttp.open(method, url, true);
    xmlhttp.onload = function () {
        if (xmlhttp.status === 200) {

            settings = JSON.parse(xmlhttp.responseText);

            // if(settings.debug){
            //     console.log("EnvironmentLoader", settings);
            // }
            
            // Rewrite AppConfig setting
            // AppConfig.settings are loaded at config.ts -> load();
            // AppConfig.settings = settings;
            
            resolve(settings);
        } else {
            resolve(defaultEnvironment);
        }
    };
    xmlhttp.send();
});