<mat-toolbar color="primary" class="toolbar">
    <div class="toolbar-left">
      <a class="logo-text" routerLink="/">Nettingale</a>
    </div>
  
    <div *ngIf="!(isAuthenticated$ | async)">
      <button mat-button class="login-button" routerLink="/main/login">
        <mat-icon>login</mat-icon>
        Login/SignUp
      </button>
    </div>
    <div class="toolbar-right" *ngIf="isAuthenticated$ | async">
      <button mat-button class="circle-button" (click)="addNewWebsite()">
        <mat-icon>add</mat-icon>
      </button>
  
      <button mat-button class="dashboard-button" routerLink="/business/dashboard">
        <mat-icon>dashboard</mat-icon>
        Dashboard
      </button>
  
      <button mat-button class="circle-button" [matMenuTriggerFor]="profileMenu">
        <span class="profile-initial">{{initials}}</span>
      </button>
      

      <mat-menu #profileMenu="matMenu" class="profile-menu" xPosition="before">
        <div class="profile-header">
          <div class="profile-initial large">{{initials}}</div>
          <div class="profile-name">User</div>
          <div class="profile-email">{{userEmail}}</div>
        </div>
        
        <mat-divider></mat-divider>
        
        <button mat-menu-item routerLink="/business/dashboard/my-profile">
          <mat-icon>person</mat-icon>
          <span>My Profile</span>
        </button>
        
        <button mat-menu-item routerLink="/business/dashboard/billing">
          <mat-icon>credit_card</mat-icon>
          <span>Billing</span>
        </button>
        
        <mat-divider></mat-divider>
        
        <button mat-menu-item (click)="logout()">
          <mat-icon>logout</mat-icon>
          <span>Logout</span>
        </button>
      </mat-menu>
    </div>
  </mat-toolbar>