import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LandingPageContainerComponent } from './landing-page-container/landing-page-container.component';
import { AppToolbarModule } from '../toolbar/toolbar.module';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';


@NgModule({
  declarations: [
    LandingPageContainerComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatButtonModule,
    MatIconModule,
    MatCardModule,
    MatIconModule,
    MatExpansionModule,
    AppToolbarModule
  ]
})
export class LandingPageModule { }
