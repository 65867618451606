import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { AppConfig } from '../_configs/_index';


@Injectable()
export class AppJwtInterceptor implements HttpInterceptor {

    private readonly appMode: boolean = AppConfig.settings.debug;
   
    constructor(private cookieService: CookieService ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        
        let currentUserCookie = this.cookieService.get('currentUser');
        let modifiedRequest: HttpRequest<any> = request;
        if (currentUserCookie) {
            let currentUser = JSON.parse(currentUserCookie);

            // if(this.appMode){
            //     console.log("AppJwtInterceptor", currentUser);
            // }	

            modifiedRequest = request.clone({
                setHeaders: {
                    'Access-Control-Allow-Origin': '*', // Required for CORS support to work
                    'Access-Control-Allow-Credentials': 'true',
                    'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS, HEAD', 
                    'Access-Control-Allow-Headers': 'Content-Type, Authorization, X-Requested-With',
                    'Authorization': `Bearer ${currentUser.token}` // Required for cookies, authorization headers with HTTPS
                }
            });
        }
        return next.handle(modifiedRequest || request);
    }
}

