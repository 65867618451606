import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-landing-page-container',
  templateUrl: './landing-page-container.component.html',
  styleUrls: ['./landing-page-container.component.scss']
})

export class LandingPageContainerComponent {
  targetUsers = [
    { 
      title: 'Small Business Owners',
      icon: 'store',
      description: 'Perfect for local businesses looking to establish their online presence.'
    },
    { 
      title: 'Bloggers',
      icon: 'edit',
      description: 'Ideal for content creators who want a reliable platform for their stories.'
    },
    { 
      title: 'Online Shops',
      icon: 'shopping_cart',
      description: 'Great for e-commerce businesses starting their online journey.'
    },
    { 
      title: 'Portfolio Sites',
      icon: 'web',
      description: 'Showcase your work with a professional and responsive website.'
    }
  ];

  constructor(private router: Router) {}

  navigateToSignup() {
    this.router.navigate(['/main/login/registration']);
  }
}